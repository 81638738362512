/* Fonts */
$primary-font: 'Manrope', sans-serif;

$primary-font-size: 1.4rem;

/* Colors */
$primary-color: #18c4af;
$secondary-color: #11a895;
$text-black: #000000;
$text-white: #fff;
$primary-bg-dark: #1d1d1d;
$primary-bg-white: #fff;
$secondary-background: #eff6f8;
$gray-text: #444444;
$bold-gray-text: #424242;
$text-pink-secondary: #888;
$mint-green: #18c3af;
$primary-border-color: #e2e2e2;
$green-main: #4ac86d;
$honey-dew: #e9f8ed;
$ghost-white: #f8f9fb;
$red-main: #ff7070;
$chinese-silver: #cbcbcb;
$approx-white-smoke: #fff1f1;

// Screen
$breakpoint-xxl: 1600px;
$breakpoint-mobile: 428px;
$breakpoint-ipad: 768px;

//font
$manrope-14-20-w500: normal normal 500 14px/20px Manrope;
$manrope-14-20-w700: normal normal 700 14px/20px Manrope;
$manrope-14-20-w300: normal normal 300 14px/20px Manrope;

$manrope-16-22-w400: normal normal 400 16px/22px Manrope;
$manrope-16-22-w500: normal normal 500 16px/22px Manrope;
$manrope-16-22-w700: normal normal 700 16px/22px Manrope;

$manrope-18-26-w400: normal normal 400 18px/26px Manrope;
$manrope-18-26-w700: normal normal 700 18px/26px Manrope;

$manrope-20-28-w700: normal normal 700 20px/28px Manrope;

$manrope-24-32-w700: normal normal 700 24px/32px Manrope;

$manrope-30-42-w700: normal normal 700 30px/42px Manrope;

$manrope-36-50-w700: normal normal 700 36px/50px Manrope;
