@import '../../assets/styles/variables.scss';
@import '../../assets/styles/global/mixin.scss';

.homepage {
  .containerNotNft {
    width: 100%;
  }
  .section-title {
    text-align: center;

    font-weight: 700;
    font-size: 3.6rem;
    line-height: 5rem;

    color: $bold-gray-text;

    &__dark {
      color: $text-black;
    }

    @include tablet {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  .background {
    width: 100%;
    height: calc(100vh - 8.4rem);

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(74.21deg, #eff6f8 26.99%, rgba(239, 246, 248, 0) 83.8%),
      url('../../assets/images/logo-ver2.png');
    backdrop-filter: blur(6px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &__logo-wrapper {
      flex: 1;
      padding-left: 10%;
      padding-right: 5%;

      .logo {
        display: inline-block;
        width: 100%;
      }

      p {
        font-weight: 500;
        font-size: 10rem;
        line-height: 13.5rem;
        letter-spacing: 0.15em;
        color: $mint-green;

        margin-top: 1.6rem;
      }
    }

    &__model {
      padding: 1.2% 0;
      height: 100%;
      opacity: 0.95;

      &:hover {
        @include bounce;
        filter: brightness(1.05);
        opacity: 1;
      }
    }

    @include tablet {
      height: auto;
      flex-direction: column;

      &__logo-wrapper {
        margin: 4.5rem 15% 0 5%;
        padding: 0;
        .logo {
          width: 300px;
        }
        p {
          font-size: 7rem;
          line-height: 9.4rem;
          letter-spacing: 0.15em;
        }
      }

      &__model {
        width: 120%;
        padding-bottom: 1.6rem;
      }
    }
  }

  .about-section {
    display: flex;
    justify-content: space-between;
    margin: 7.8rem 18rem 0 18rem;
    @include border-gradient;
    border-image-source: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    );
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding-bottom: 5.2rem;

    .about {
      font-weight: 500;
      font-size: 3rem;
      line-height: 4rem;
      transition: all 0.3s;

      &__index {
        color: $primary-color;
      }

      &__text {
        color: $text-pink-secondary;
      }

      &:hover {
        transform: scale(1.5) translateY(-1px);
      }

      &:hover {
        .about__text {
          color: $text-black;
        }
      }
    }

    @include tablet {
      flex-direction: column;
      margin: 3.8rem;
      border: none;

      padding-bottom: 0;

      .about {
        font-size: 2.4rem;
        line-height: 3.2rem;

        &:not(:last-child) {
          padding-bottom: 1.6rem;
        }

        &:hover {
          font-size: 2.8rem;
          line-height: 3.8rem;
          transform: none;
        }
      }
    }
  }

  .body-section {
    margin: 6.5rem 22% 6.5rem 22%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .model-card-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .model-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        aspect-ratio: 1;

        .container {
          position: relative;
          width: 100%;
          height: 100%;
          margin-top: 5%;

          border: double 2px transparent;
          border-radius: 50%;
          // background-image: linear-gradient(white, white),
          //   radial-gradient(circle at top, rgba(110, 153, 60, 0.2), rgba(110, 153, 60, 1));
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;

          .model {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 110%;
            height: 110%;
            transform: translate(-50%, -55%);

            @include tablet {
              width: 130%;
              height: 130%;
            }
          }
        }

        &:hover {
          @include bounce;
        }

        &--1 {
          .container {
            background-image: url('../../assets/images/char-1-home-bg.png');
          }
        }

        &--2 {
          .container {
            background-image: url('../../assets/images/char-2-home-bg.png');
          }
        }
      }
    }

    .power-container {
      display: flex;
      justify-content: center;
      align-items: end;

      &::before,
      &::after {
        height: 2px;
        flex: 1;
        content: '';
        transform: translateY(-120%);
      }

      &::before {
        background: linear-gradient(270deg, #18c3af 0%, rgba(24, 195, 175, 0) 100%);
      }

      &::after {
        background: linear-gradient(270deg, rgba(24, 195, 175, 0) 0%, #18c3af 100%);
      }
    }

    .team-logo-mobile {
      display: none;
    }

    .process-container__wrapper {
      --proces-height: 8rem;
      --deg: 110deg;
      margin-top: 1rem;

      position: relative;

      @include tablet {
        --proces-height: 6.2rem;
        --deg: 100deg;
      }

      .team-logo {
        position: absolute;
        top: 50%;

        &__dragoon {
          right: -5%;
          transform: translate(120%, -50%);
        }

        &__sentinel {
          left: -5%;
          transform: translate(-120%, -50%);
        }
      }

      .process-container {
        position: relative;
        height: var(--proces-height);
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(
          var(--deg),
          #1fa9b5 0% var(--process-percent),
          #e47559 var(--process-percent)
        );

        @include tablet {
          clip-path: polygon(0 50%, 5% 0, 95% 0, 100% 50%, 95% 100%, 5% 100%);
        }

        .process-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          flex: 1;

          .title {
            display: flex;
            align-items: center;

            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #fafafa;

            @include tablet {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 2rem;
            }

            span {
              display: block;
              margin-left: 0.7rem;
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2rem;

              color: #ffffff;

              padding: 0.2rem 1.4rem;
              background: rgba(#fff, 0.15);
              border-radius: 30px;

              @include tablet {
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.6rem;
                padding: 0.2rem 1rem;
              }
            }
          }
          .percent {
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.2rem;

            color: #ffffff;

            @include tablet {
              font-size: 1.6rem;
              line-height: 2.2rem;
            }
          }
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          aspect-ratio: 48/80;
          top: 0;
          z-index: 99;
          height: var(--proces-height);
          background-size: contain;
          background-repeat: no-repeat;

          @include tablet {
            aspect-ratio: 17/64;
          }
        }

        &::before {
          background-image: url('../../assets/images/PolygonSynphy.png');
          left: 0;
          transform: translateX(-90%);

          @include tablet {
            background: none;
            // background-color: #1fa9b5;
            // transform: translateX(-100%);
            // clip-path: polygon(100% 0, 0 50%, 100% 100%);
          }
        }

        &::after {
          right: 0;
          background-image: url('../../assets/images/PolygonFreiya.png');
          background-size: contain;
          background-repeat: no-repeat;
          transform: translateX(90%);

          @include tablet {
            background: none;
            // background-color: #e47559;
            // transform: translateX(100%);
            // clip-path: polygon(0 0, 0 100%, 100% 50%);
          }
        }

        &--shylphy {
          &::after {
            background-image: url('../../assets/images/PolygonSynphyReverse.png');
          }
        }

        &--freiya {
          &::before {
            background-image: url('../../assets/images/PolygonFreiyaReverse.png');
          }
        }
      }
    }

    .actions-container {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .actions {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }

    @include tablet {
      display: block;
      margin: 0 2.8rem 5.5rem 2.8rem;
      .model-card {
        .container {
          width: 20.4rem;
          height: 20.4rem;
        }

        &:hover {
          animation: none;
        }
      }

      .team-logo-mobile {
        display: block;
        margin-top: 2rem;

        display: flex;
        justify-content: center;

        .team-logo {
          display: flex;
          justify-content: center;
          flex: 1;

          img {
            width: 4.4rem;
          }
        }
      }

      .process-container__wrapper {
        margin-top: 1rem;

        .team-logo {
          display: none;
        }
      }

      .actions-container {
        margin-top: 1.5rem;
        .ant-space-item {
          width: 100%;
          button {
            width: 95%;
          }
        }
      }
    }
  }

  .chart-section {
    margin: 7rem 8% 7rem 8%;
    padding: 5rem 9.6rem;
    border-radius: 1rem;
    box-shadow: 0px 2rem 4rem rgba(0, 0, 0, 0.08);

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      align-items: center;
      .title {
        font-size: 2.7rem;
        line-height: 3.75rem;

        color: $bold-gray-text;

        @include tablet {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }

      .ant-select {
        min-width: 15rem;
      }
    }

    &__main {
      height: 60vh;
    }

    @include tablet {
      margin: 5.5rem 2rem 6rem 2rem;
      padding: 2.5rem 1.5rem;

      &__main {
        height: 45vh;
      }
    }
  }

  .weapon-purchase {
    padding: 7rem 8% 7rem 8%;
    background-color: $secondary-background;
    text-align: center;

    & > * {
      text-align: left;
    }

    h1 {
      text-align: center;
      margin-bottom: 4rem;
    }

    .weapon-container {
      display: grid;
      grid-template-columns: repeat(var(--grid-item), 1fr);
      grid-gap: 2.4rem;
      transition: all 0.4s;
      padding: 0 var(--padding);
      margin-bottom: 3rem;

      .weapon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: $primary-bg-white;
        padding: 1.7rem;
        padding-bottom: 2.2rem;
        box-shadow: 0px 0.25rem 1rem rgba(0, 0, 0, 0.05);
        border-radius: 1rem;
        transition: all 0.4s;
        cursor: pointer;

        &__logo-wrapper {
          position: relative;
          background-image: url(../../assets/images/model-bg.png);
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0.8rem;

          aspect-ratio: 1 / 1;

          margin-bottom: 1.6rem;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            // padding: 4rem;
            background: #ffffff;
            object-fit: contain;
          }
        }

        &__name {
          color: $text-black;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.2rem;
          margin-bottom: 0.6rem;
          @include text-ellipsis(1);
        }

        &__description {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: $secondary-color;

          margin-bottom: 1.2rem;

          @include text-ellipsis(2);
        }

        &__price {
          font-weight: 700;
          font-size: 2.4rem;
          line-height: 3.2rem;
          color: $bold-gray-text;
        }
      }

      &:hover .weapon:hover {
        transform: scale(1.1) translateY(-1.5rem);
        box-shadow: 0px 2rem 4rem rgba(0, 0, 0, 0.1);
      }

      &:hover .weapon {
        transform: scale(0.85);
      }
    }

    @include tablet {
      padding: 5.5rem 0 6rem 0;
      overflow-x: scroll;

      h1 {
        margin-bottom: 0;
      }

      .weapon-container {
        overflow-x: scroll;
        display: flex;
        grid-gap: 1.6rem;
        padding: 1rem;

        .weapon {
          min-width: 70%;
          &:first-child {
            margin-left: 1.6rem;
          }
        }

        &:hover .weapon:hover {
          transform: translateY(0.1rem);
          box-shadow: 0px 2rem 4rem rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .recommend-preset {
    text-align: center;
    margin-bottom: 6rem;

    h1 {
      margin-top: 6rem;
    }

    .recommend-preset-container {
      margin-top: 5%;
      margin: 5% 15% 0;
      display: grid;
      grid-template-columns: var(--grid-template-columns);
      padding: 0 var(--padding);
      grid-gap: 3rem;
      align-items: end;

      .preset {
        cursor: pointer;
        width: 100%;
        transition: all 0.2s;

        &__detail {
          padding: 2rem 3.3rem 2.5rem 3.3rem;

          box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);

          border: 1px solid transparent;
          border-radius: 1rem;

          .logo {
            width: 100%;
            aspect-ratio: 0.84;
          }

          .price {
            margin-top: 2.4rem;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 3.2rem;

            color: $bold-gray-text;
          }

          .weapons-name {
            margin-top: 1.2rem;

            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2.2rem;

            color: $bold-gray-text;
            @include text-ellipsis(1);
          }

          &__main {
            background: linear-gradient(#fff 0 0) padding-box,
              linear-gradient(to bottom, rgba(55, 183, 178, 0.2), rgba(55, 183, 178, 1)) border-box;

            .logo {
              // transform: scaleY(1.165);
              transform-origin: bottom;

              @include tablet {
                transform: scaleY(1);
              }
            }

            .price {
              margin-top: 2.6rem;
            }
          }
        }

        &__name {
          padding: 0 1.5rem;
          margin-top: 2rem;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.2rem;

          color: $bold-gray-text;
          @include text-ellipsis(1);
        }

        &__description {
          margin-top: 1rem;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;

          color: $bold-gray-text;

          min-height: 2rem * 2;

          @include text-ellipsis(2);
          padding: 0 0.75rem;
        }

        &:hover {
          transform: translateY(-2px);
        }

        &:hover .preset__detail {
          border-width: 3px;
        }

        &:first-child .preset__detail__sub {
          background: linear-gradient(#fff 0 0) padding-box,
            linear-gradient(to bottom, rgba(224, 53, 53, 0.2), rgba(224, 53, 53, 1)) border-box;
        }

        &:last-child .preset__detail__sub {
          background: linear-gradient(#fff 0 0) padding-box,
            linear-gradient(to bottom, rgba(236, 142, 51, 0.2), rgba(236, 142, 51, 1)) border-box;
        }
      }
    }

    button {
      margin-top: 1.75rem;
    }

    @include tablet {
      .recommend-preset-container {
        grid-template-columns: 1fr;
        grid-gap: 4.5rem;
        padding: 0;
        margin: 5% 10%;

        .preset {
          &__detail {
            &__2 {
              .logo {
                transform: none;
              }
            }
          }
        }
      }
    }
  }

  .customize {
    text-align: center;

    h1 {
      margin-top: 6rem;
    }

    .customize-container {
      .card-wrapper {
        .customize-card {
          aspect-ratio: 1 / 1;
          background-image: url('../../assets/images/model-bg.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 90%;
            aspect-ratio: 1 / 1;
          }
        }

        .revert {
          width: 90%;
          margin: auto;
          height: var(--invert-shadown-size);
          aspect-ratio: 1 / 1;
          transform: rotate(180deg);

          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }

  .sell-secondary-btn {
    position: relative;

    width: 50%;
    margin: 4rem auto 0 auto;
    padding-bottom: 4.5rem;
    overflow: hidden;
    text-align: center;
    height: 50rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../assets/images/home-button-bg.jpg');
      background-position: top center;
      mix-blend-mode: multiply;

      width: 100%;
      height: 100%;
      opacity: 0.7;
    }

    button {
      width: 80%;

      position: relative;
      z-index: 1;

      margin-top: 20rem;
    }

    &:hover::before {
      opacity: 0.9;
    }

    @include tablet {
      width: 100%;
      padding-bottom: 2.5rem;
      height: 30rem;

      button {
        margin-top: 17rem;
      }
    }

    @include xxl {
      width: 40%;
    }
  }
}
