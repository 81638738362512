@import '../variables.scss';

.page {
  $mobile-padding: 2rem 1.6rem 4rem 1.6rem;

  background: linear-gradient(
    $secondary-background,
    $secondary-background 25.6rem,
    transparent 25.6rem,
    transparent calc(100% - 25.6rem)
  );
  padding: 3rem 10rem 8rem 10rem;

  &--padding-lg {
    padding: 3rem 21.5rem 8rem 21.5rem;

    @include tablet {
      padding: $mobile-padding;
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--mobile {
    padding: 1.7rem;
  }

  @include tablet {
    padding: $mobile-padding;
  }
}
