@import 'src/assets/styles/variables.scss';

.containerMyPage {
  .boxMainInfo {
    border-bottom: 1px solid $primary-border-color;
    padding-bottom: 45px;
    .boxImage {
      width: 100%;
      .imageCover {
        width: 100%;
        height: 256px;
        object-fit: cover;
      }
      .avatarWrapper {
        text-align: center;
        .avatar {
          margin-top: -120px;
          border: 4px solid $primary-bg-white;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .boxInfo {
      width: 60%;
      margin: auto;
      text-align: center;
      p {
        margin-bottom: 1.4rem;
      }
    }
  }
  .boxMainContent {
    display: flex;
    .boxMenuFilter {
      width: 25%;
      padding: 35px 30px 35px 65px;
      border-right: 1px solid $primary-border-color;
      h2 {
        margin-left: 20px;
      }
      .menuFilter {
        font-size: 16px;
        color: $text-black;
        .itemMenu {
          position: relative;
          margin-bottom: 10px;
        }
        .iconRight {
          position: absolute;
          top: 32%;
          right: 10px;
        }

        .ant-menu-submenu {
          overflow: hidden;

          .ant-menu-title-content {
            margin-left: 0;
          }
          .ant-menu-sub {
            background: none;
            &::before {
              content: '';
              position: absolute;
              border-left: 1px solid $primary-border-color;
              height: 12.5rem;
              left: 3.5rem;
            }
          }
          .ant-menu-submenu-arrow {
            display: none;
          }
          &-open {
            .icon-down {
              transform: rotateZ(180deg);
            }
          }
        }
      }
    }

    .boxContentFilter {
      width: 75%;
      padding: 32px 100px 90px 32px;
      h2 {
        margin-bottom: 0;
      }
      .boxInputSearch {
        width: 60%;
      }
      .notFoundNft {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .footerPagination {
        margin-top: 2rem;
        text-align: right;
      }

      .ant-form-item-control-input {
        min-width: 15rem;
      }
    }
  }

  .btnClearFilter {
    &:hover {
      color: $bold-gray-text;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .boxInfo {
    width: 88% !important;
    .showTextDes {
      margin-bottom: 1.4rem;
      .actionShow {
        font-weight: 700;
      }
      p {
        margin-bottom: 0 !important;
      }
    }
  }
  .boxMainContent {
    flex-direction: column;
    width: 88% !important;
    margin: auto;
    .boxMenuFilter {
      padding: 0 !important;
      width: 100% !important;
      margin: auto;
      border-right: none !important;
      .menuFilter {
        font-size: 14px !important;
        .itemMenu {
          position: relative;
          margin-bottom: 10px;
        }
        .iconRight {
          display: none;
        }
      }
    }
    .boxContentFilter {
      .titleContent {
        margin-top: 10px !important;
        margin-bottom: 5px !important;
      }
      width: 100% !important;
      padding: 0 !important;
      margin-bottom: 40px;
      .boxInputSearch {
        width: 100% !important;
      }
    }
  }
}

.modalDetail2ndNft {
  .loadingModal {
    width: 100%;
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-content {
    padding: 10px 5px;
  }
}
