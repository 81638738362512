@import 'src/assets/styles/variables.scss';
.wrapperLayout {
  display: flex;
  flex-direction: column;
  .bodyContent {
    margin-top: 84px;
    min-height: 650px;
  }
}
.customModalUserBlock {
  .noti {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 50px;
    text-align: center;
  }
  .boxBtn {
    display: flex;
    justify-content: center;
  }
}
