@import 'src/assets/styles/variables.scss';
.wrapperSelectFilter {
  margin-top: 20px;
  .btnSelectFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    cursor: pointer;
    padding: 0.85rem 1.5rem;
    border-radius: 6px;
    transition: all 0.3s ease;
    border: 1px solid #e2e2e2;
    background-color: $text-white;
    margin-right: 10px;
    .btnTitle {
      margin: 0 5px;
    }
    &:hover {
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(-0.75rem);
      box-shadow: 0 2rem 4rem rgba(#000, 0.1);
    }
  }
}
