@mixin btnLink {
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
@mixin flex-row-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@mixin border-gradient {
  border: 1px solid;
  border-image-slice: 1;
}

@mixin bounce {
  animation: bounce 1.5s ease infinite 0.1s;

  @keyframes bounce {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

@mixin bounce-up {
  animation: bounce-up 1.5s ease infinite 0.1s;

  @keyframes bounce-up {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10%);
    }

    100% {
      transform: translateY(0);
    }
  }
}

@mixin shake {
  animation: shake 0.5s ease 3s;
  animation-iteration-count: 5;

  @keyframes shake {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
      transform: translate(-3px, 1px) rotate(1deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(0deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(1deg);
    }
    80% {
      transform: translate(1px, -1px) rotate(0deg);
    }
    100% {
      transform: translate(0px, 0px) rotate(0deg);
    }
  }
}

@mixin tablet {
  @media screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin xxl {
  @media screen and (min-width: $breakpoint-xxl) {
    @content;
  }
}

@mixin text-ellipsis($value) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $value) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $value;
    -webkit-box-orient: vertical;
  }
}

@mixin boxAbsoluteCard($top, $rightOrLeft, $type: 'right') {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: $primary-bg-white;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  padding: 5px 10px;
  top: $top;
  @if $type == 'right' {
    right: $rightOrLeft;
  }
  @if $type == 'left' {
    left: $rightOrLeft;
  }
  @content;
}
