@import '../../assets/styles/variables.scss';

.imageUploaderCircle,
.imageUploaderRetangle {
  // overflow: hidden;
  position: relative;
  .icon-reset-image {
    position: absolute;
    color: red;
    cursor: pointer;
    top: 40%;
    right: -25px;
    font-size: 1.6rem;
  }
  .imageHover {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4242425c;
    color: white;
    display: none;
  }

  .input-upload-image:hover .imageHover {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-upload-image {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .input-upload-model {
    width: 100%;
    height: 100%;
    .input-box {
      border: 1px solid #cbcbcb;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
    }
    .model-button {
      width: 68px;
      height: 36px;
      background: #18c4af;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .input-name {
      width: calc(100% - 68px);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: rgba(66, 66, 66, 0.6);
    }
  }
  .input-upload-preview {
    width: 100%;
    height: 100%;
    background: rgba(24, 195, 175, 0.05);
    border: 2px dashed #18c4af;
    border-radius: 12px;
    .preview-upload {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #424242;
      }
      span {
        font-size: 14px;
        line-height: 20px;
        color: rgba(66, 66, 66, 0.6);
      }
    }
    .img-preview2d {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
    .viewer-box {
      width: 100%;
      height: 100%;
      text-align: right;
      position: relative;
      .upload-button:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
      label {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        margin: 10px;
      }
      .image-file{        
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #f3f4f4;
        background-position: center;
        border-radius: 10px;
        height: 100%;
      }
    }
    .ratio{
      margin-bottom: 20px;
      margin-top: 10px;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .input-upload-preview:hover .preview-upload-hover {
    display: flex;
  }

  .uploadButton {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.image-file{        
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f3f4f4;
  background-position: center;
  border-radius: 10px;
  height: 100%;
}
.imageUploaderRetangle {
  .input-upload-image,
  .input-upload-image img,
  .imageHover {
    border-radius: 20px;
  }
}

.imageUploaderCircle {
  .input-upload-image,
  .input-upload-image img,
  .imageHover {
    border-radius: 50% !important;
  }
}
