@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/global/mixin.scss';

button {
  border: none;
  outline: none;
}

.btn {
  width: fit-content;
  // font-family: "Manrope";
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  cursor: pointer;

  padding: 0.85rem 2rem;

  color: $text-white;
  background-color: $primary-color;
  border-radius: 6px;
  box-shadow: 0 1rem 2rem rgba(#000, 0.05);
  transition: all 0.3s ease;

  .anticon {
    margin-right: 1.2rem;
    width: 1.4rem;
    height: 1.2rem;
    color: currentColor;
  }

  &--small {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0.8rem 1.4rem;

    @include tablet {
      padding: 1rem 2rem;
    }
  }

  &--cardNft {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0.84rem 2.3rem;
  }

  &--playNft {
    display: flex;
    align-items: center;
    background: transparent;
    color: $primary-color;
    padding: 6px 16px;
    font-weight: 700;
    font-size: 1.4rem;
    border: 1px solid $primary-color;
    border-radius: 40px;
  }

  &--connectWallet {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0.84rem 2.3rem;
    margin-right: 15px;
  }

  &--large {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 4rem;

    @include tablet {
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding: 1.2rem 3rem;
    }
  }

  &--full {
    width: 100%;
    flex: 1;
  }

  &--mobile {
    padding: 1.5rem 0.8rem;
    line-height: 24px;
  }

  &--light-green {
    background-color: transparent;
    color: $mint-green;
    border: 1px solid currentColor;
  }

  &--light-black {
    background-color: $secondary-background;
    color: $text-black;
  }

  &--outline-light-black {
    background-color: transparent;
    color: $text-black;
    border: 1px solid $primary-border-color;
    box-sizing: border-box;
  }

  &--sylphy-primary {
    background-color: #1fa9b5;
    color: #ffffff;
  }

  &--sylphy-secondary {
    background-color: #def3f4;
    color: #1fa9b5;
  }

  &--freiya-primary {
    background-color: #e47559;
    color: #ffffff;
  }

  &--freiya-secondary {
    background-color: #fbebe6;
    color: #e47559;
  }

  &__no--selected {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 0.85rem 2rem;
    color: $text-black;
    border: 1px solid #e2e2e2;
    background-color: $text-white;
    margin-right: 10px;
  }

  &--selected {
    background-color: $primary-color;
    color: $text-white;
  }

  &--accept {
    background-color: $primary-color;
    color: $text-white;
    font-weight: 700;
    font-size: 1.6rem;
    background-color: $primary-color;
    width: 200px;
    height: 46px;
  }

  &--cancel {
    background-color: $primary-color;
    color: $text-black;
    font-weight: 700;
    font-size: 1.6rem;
    width: 200px;
    height: 46px;
    border: 1px solid #e2e2e2;
    background-color: $text-white;
  }

  &--cancel:hover {
    color: $text-black !important;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(-0.75rem);
    box-shadow: 0 2rem 4rem rgba(#000, 0.1);
  }

  &:disabled,
  &[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  &:disabled {
    color: rgba(66, 66, 66, 0.6);
    transform: none;
    box-shadow: none;
  }
}
