@import 'assets/styles/style';

// CookieConcent
#cc-main {
  /** Change button primary color to black **/
  --cc-btn-primary-bg: #18c4af;
  --cc-btn-primary-border-color: #18c4af;
  --cc-btn-primary-hover-bg: #1bdec6;
  --cc-btn-primary-hover-border-color: #1bdec6;

  .cm-wrapper {
    .cm {
      //position: fixed;
      //width: 100%;
    }

    .cm--bar {
      .cm__body {
        display: flex;
        flex-direction: row;

        .cm__btns {
          flex-direction: column;

          .cm__btn-group {
            margin-left: 0;
          }
        }
      }
    }
  }
}