@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/global/mixin.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  scroll-behavior: smooth;
}

:root {
  --primary-color: $primary-color;
  --primary-color-blur: #eadaff;
}

body {
  font-family: $primary-font;
  box-sizing: border-box;
  font-weight: 400;
  font-size: $primary-font-size;
  line-height: 1.9;
  color: $bold-gray-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

//custom-ant
.card-2ndNft {
  .ant-card-cover {
    cursor: pointer;
    height: 275px;
    border: 1px solid #e2e2e2;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    a {
      height: 100%;
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .ant-collapse-header {
    padding: 0 !important;
    font: $manrope-24-32-w700;
    display: flex;
    align-items: center !important;
    .ant-collapse-header-text {
      color: $bold-gray-text;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
    text-align: justify;
  }
}
.slider-custom .ant-image {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.ant-pagination-item-active {
  background: $primary-color !important;
  border-radius: 6px;
  a {
    color: $primary-bg-white !important;
    // font: $manrope-14-20-w700
  }
}
.ant-pagination-item {
  a {
    color: $bold-gray-text !important;
    font-weight: 500 !important;
  }

  &-active {
    background-color: $mint-green !important;
    a {
      color: #fff !important;
      font-weight: 700 !important;
    }
  }
}

.ant-space {
  width: 100%;
}

.ant-modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 2.5rem !important;
  line-height: 3.4rem;
  color: $primary-color !important;
  margin-bottom: 2rem !important;
}
.ant-table-footer {
  background-color: transparent !important;
}

@media only screen and (max-width: $breakpoint-mobile) {
  .ant-modal-title {
    font-size: 1.6rem !important;
  }
}

.ant-btn:not(:disabled):hover {
  color: #fff;
}

//custom antd and select menu horizontal
.dropdown-change-lg {
  .ant-select-selector {
    font-size: 17px !important;
    font-weight: 500;
    color: black;
  }
  .ant-select-arrow {
    color: #424242;
  }
}

.custom-loading-modal {
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none;
    width: auto;
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
}

.react-horizontal-scrolling-menu--item {
  flex-shrink: 0;
}

.ant-skeleton {
  width: 100% !important;
  height: 100% !important;

  .ant-skeleton-image {
    width: inherit !important;
    height: inherit !important;
  }
}
