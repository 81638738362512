@import 'assets/styles/style';

.drawer__header {
  max-height: 100vh;

  &--content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .boxFooterDrawer {
    display: flex;
    justify-content: space-between;

    .boxAccountAddress {
      font-weight: 500;
      cursor: pointer;
      font-size: 18px;
      border: 2px solid #404040;
      border-radius: 6px;
      padding: 0 20px;
      margin-left: 15px;
      width: 188px;
      text-align: center;

      .accountAddress {
        margin-right: 30px;
      }
    }

    .btnLogoutMobile {
      background-color: $text-white;
      padding: 5px 10px;
      border: 2px solid #404040;
      border-radius: 6px;
      box-sizing: border-box;
      font-weight: 500;
    }
  }
}

.boxDropdownLogout {
  .ant-space {
    width: 8% !important;
  }
}

.dropdownLogout {
  ul {
    li {
      padding: 10px 20px !important;
      color: #424242;
      font-weight: 500;
    }
  }
}
