@import '../../assets/styles/variables.scss';
@import '../../assets/styles/global/mixin.scss';

.carousel-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .carousel {
    position: relative;
    perspective: 50rem;
    transform-style: preserve-3d;
    backface-visibility: hidden;

    &--item {
      position: absolute;
      width: 100%;
      height: 100%;

      transition: all 0.25s ease-out;
    }
  }
}
.nav {
  position: absolute;
  top: 50%;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid $primary-border-color;
  border-radius: 50%;
  color: $bold-gray-text;

  width: 6rem;
  height: 6rem;

  font-size: 2rem;

  cursor: pointer;
  user-select: none;
  background: $text-white;

  svg {
    color: currentColor;
    margin: auto;
  }

  &--left {
    transform: translateX(100%) translateY(-50%);
  }

  &--right {
    right: 0;
    transform: translateX(-100%) translateY(-50%);
  }

  @include tablet {
    width: 5rem;
    height: 5rem;

    font-size: 1.5rem;
    &--left {
      transform: translateX(50%) translateY(-50%);
    }
    &--right {
      right: 0;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
