.containerNotNft {
  text-align: center;
  width: 65%;
  border-radius: 10px;
  padding: 40px 0;
  .title {
    margin: 24px 0;
    font-weight: 700;
    font-size: 1.6rem;
    padding-left: 10px;
  }
}
.containerNotNftBorder {
  border: 1px solid #cbcbcb;
}
