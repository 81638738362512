@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/global/mixin.scss';

.not-found {
  width: 100%;
  min-height: 100vh;
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  &__image-container {
    width: fit-content;
    margin: auto;
    padding-top: 5rem;
    margin-bottom: 5rem;

    background-image: url('../../assets/images/not-found-bg.png');
    background-position: top center;
    background-size: cover;

    font-weight: 800;
    font-size: 25rem;
    line-height: 1;

    color: $bold-gray-text;

    span {
      display: inline-block;
      @include shake();
      vertical-align: bottom;
    }

    img {
      display: inline-block;
      padding: 0 1.5rem;
      height: 21.8rem;
      margin-bottom: 1rem;

      @include bounce-up();
    }
  }

  &__main {
    margin-bottom: 4.2rem;

    h1 {
      font-weight: 700;
      font-size: 3rem;
      line-height: 4.2rem;
      color: $bold-gray-text;
    }

    h2 {
      margin-top: 1.6rem;

      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $bold-gray-text;
    }

    button {
      margin-top: 1rem;
    }
  }

  &__footer {
    display: inline-block;
    background: $honey-dew;
    border-radius: 1rem;
    padding: 2.8rem 4rem;
    text-align: left;

    h3 {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $bold-gray-text;

      margin-bottom: 3rem;
    }

    ul {
      margin-left: 1.5rem;
      li {
        list-style-type: disc;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $bold-gray-text;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @include tablet {
    padding: 2rem;

    &__image-container {
      padding-top: 2rem;
      margin-bottom: 2rem;
      font-size: 15rem;
      img {
        height: 10rem;
      }
    }
  }
}
