@import 'assets/styles/style';
.containerFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/images/bg-footer.png);
  color: white;
  .wrapperFooter {
    margin: 20px auto;
    width: 80%;
    .contentFooter {
      padding-top: 32px;
      ul {
        .title {
          font-size: 20px;
          font-weight: 700;
        }
        .title::after {
          content: '';
          display: block;
          position: relative;
          width: 12%;
          border: 1px solid $primary-color;
          margin-top: 5px;
        }
        li {
          margin-bottom: 15px;
          a {
            color: white;
          }
        }
      }
    }
  }
  .boxLicense {
    // height: 74px;
    .boxLicenseInline {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      height: 100%;
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .license {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
      font-size: 16px;
      font-family: 'Gineso';
    }
    .listIconSocial {
      display: flex;
      justify-content: center;
      align-items: center;
      .boxIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        margin-right: 24px;
      }
    }
  }
  .logo{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 24px;
    height: 52px;
    width: 52px;
  }
}

@media screen and (max-width: 500px) {
  .headerFooter {
    flex-direction: column;
  }
  .wrapperFooter {
    width: 87% !important;
  }
  .boxLicense {
    height: 110px !important;

    .boxLicenseInline {
      display: block !important;
      width: 87% !important;
      .license {
        margin-top: 10px;
      }
      .listIconSocial {
        justify-content: flex-start !important;
        margin-top: 16px;
      }
    }
  }

  .boxLogo {
    margin-bottom: 30px;
  }
}
