.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center{
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mt-xs {
}

.mt-sm {
}

.mt-md {
}

.mt-lg {
  margin-top: 2.4rem;
}

.mt-xl {
  margin-top: 4rem;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
