@import 'assets/styles/style';
.containerHeader {
  height: 84px;
  background-color: $primary-bg-white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 1000;

  .boxHeader {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      margin-left: 100px;
      margin-right: 30px;
    }
    .menuHeader {
      width: 65%;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: 500;
      border-bottom: none;
      border-inline-end: none;
      color: $text-black;
    }
    .boxEndHeader {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 100px;
      width: 35%;
      text-align: end;
      .boxNoti {
        padding: 0 12px 0 0;
        position: relative;
      }

      .boxAccountAddress {
        font-weight: 500;
        font-size: 18px;
        border: 2px solid #404040;
        border-radius: 6px;
        padding: 0 20px;
        margin-right: 15px;
        font-weight: 500;
        cursor: pointer;
      }
      .boxMutiLanguage {
        border-left: 1.2px solid #424242;
      }
    }
  }

  //mobile
  .boxHeaderMobile {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 33px;
    position: relative;
    .menuIcon {
      padding-left: 12px;
      padding-top: 12px;
      display: flex;
    }
  }

  .drawerHeader {
    height: 100vh;
    background-color: red !important;
  }
}

.boxAlertMobile {
  text-align: start;
  .boxAlertHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .boxAlertTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
  .boxAlertList {
    max-height: 300px;
    overflow-y: scroll;
  }
  .boxAlertNoticeRead {
    background-color: #f2f2f2;
  }
  .boxAlertNotice {
    padding: 10px 5px;
    border-bottom: 1px solid #e2e2e2;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .boxAlertMore {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #18c4af;
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    p {
      cursor: pointer;
    }
  }
  .boxContent {
    display: flex;
    align-items: center;
  }
  .boxDescription {
    margin-left: 10px;
  }
}

.boxAlertDesktop {
  text-align: start;
  .boxAlertHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .boxAlertTitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 0px;
    }
  }
  .boxAlertList {
    max-height: 300px;
    overflow-y: scroll;
  }
  .boxAlertLoading {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .boxAlertList::-webkit-scrollbar {
    width: 5px;
  }
  .boxAlertList::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
  }
  .boxAlertList::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    border-radius: 10px;
  }
  .boxAlertNoticeRead {
    background-color: #f2f2f2;
  }
  .boxAlertNotice {
    padding: 15px 7px 15px 10px;
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .boxAlertNotice:hover {
    background-color: #eff6f8;
  }
  .boxAlertMore {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #18c4af;
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    p {
      cursor: pointer;
    }
  }
  .boxContent {
    display: flex;
    align-items: center;
    img {
      width: 30px;
    }
  }
  .boxDescription {
    margin-left: 10px;
  }
}
@media screen and (max-width: 500px) {
  .boxHeader {
    display: none !important;
  }
}
@media (min-width: 500px) {
  .boxHeaderMobile {
    display: none !important;
  }
}
