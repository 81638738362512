@import 'src/assets/styles/variables.scss';

.model-card {
  width: 100%;
  padding: 2rem;
  border: 1px solid $primary-border-color;
  border-radius: 8px;
  margin-bottom: 2rem;

  @mixin statistic {
    width: 100%;
    padding: 0.75rem 1.5rem;
    min-height: 3.6rem;
    align-self: stretch;
    vertical-align: middle;
    text-align: center;
    border: 1px solid $primary-border-color;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $primary-bg-dark;
  }

  &__name {
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;

    color: $bold-gray-text;
  }

  .header-statistic {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid $primary-border-color;
    margin-bottom: 3rem;

    .row {
      .label {
        display: flex;
        align-items: center;
      }

      .statistic-item {
        @include statistic();
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .left-content {
    .modelWrapper {
      width: 100%;
      aspect-ratio: 1/1;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
      background: #f3f4f4;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  .right-content {
    .artist-list {
      margin-top: 1.75rem;
      display: flex;

      .creator-item {
        &:not(:last-child) {
          margin-right: 4rem;
        }

        &__name {
          display: inline-block;
          margin-left: 1rem;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;

          color: rgba(66, 66, 66, 0.6);
        }
      }

      .list-owner-item {
        display: none;
        align-items: center;
      }
      .avatar-owner {
        margin-left: 8px;
      }
    }
  }

  &__footer {
    .statistics {
      margin-top: 1.75rem;
      width: 102px;
      .statistic-item {
        @include statistic();
      }
    }
  }

  &--2nd {
    .header-statistic {
      .statistic-item {
        padding: 0.4rem 1.6rem;
        background-color: rgba(255, 112, 112, 0.1);
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem;
      margin-top: 1.75rem;
      border: 1px solid $mint-green;
      border-radius: 0.8rem;

      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      color: $mint-green;
      cursor: pointer;

      p {
        margin: 0;
        padding-left: 1rem;
      }

      svg {
        transform: scale(1.2);
        display: inline-block;
      }
    }
  }
}
