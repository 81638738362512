@import 'src/assets/styles/variables.scss';
@import 'assets/styles/global/mixin.scss';
.containerCard {
  position: relative;
  border: 1px solid #e2e2e2;
  height: 438px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
  .imageCoverCard {
    height: 270px;
    object-fit: contain;
    cursor: pointer;
    // background: url('../../../assets/images/model-bg.png');
  }
  .boxQuantity {
    @include boxAbsoluteCard(12px, 14px, 'left');
  }
  .boxTypeNft {
    @include boxAbsoluteCard(12px, 65px);
    color: $primary-color;
    font-weight: 700;
  }
  .boxCountLike {
    @include boxAbsoluteCard(12px, 14px);
  }
  .contentCard {
    h3 {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        display: inline-block;
        width: 65%;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .status {
        background-color: $approx-white-smoke;
        border-radius: 6px;
        padding: 0.6rem 1.2rem;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $green-main;
      }
    }
    p {
      margin: 5px 0 15px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 45px;
      word-break: break-all;
    }
    .footerCard {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .reason {
        color: $red-main;
      }
      
      .action {
        display: flex;
        margin-top: 20px;
        .eye{
          margin-right: 5px;
        }
        .copy{
          margin-left: 10px;
        }
      }
    }
    .formatText{      
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
}

.profileEditContainer {
  width: 75%;
  padding: 32px 100px 110px 32px;
  .editProfileForm {
    .avatarSelectTitle {
      padding-left: 50px;
    }
    .coverImageSelectTitle {
      padding-left: 43px;
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .profileEditContainer {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid $primary-border-color;
    margin: 20px 0;

    .editProfileForm {
      .avatarSelectTitle {
        padding-left: 0;
      }
      .coverImageSelectTitle {
        padding-left: 0;
      }
      .submitProfileButton {
        width: 100%;
      }
      .submitProfileButton:hover {
        color: #fff;
      }
    }
  }

  //modal detail
  .totalBox {
    margin: 0 -10px 0 -20px!important;
    .totalAmount {
      min-width: 0!important;
      margin-left: 30px;
    }
  }
}

@media only screen and (max-width: $breakpoint-ipad) {
  .totalBox {
    .discount {
      margin: 0 0 10px 10px!important;
    }
  }
}
//table transaction
.paginationTransaction {
  margin-top: 2rem;
  text-align: right;
}
.btnActionTransaction {
  cursor: pointer;
  padding: 7px 20px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  background: transparent;
  font-weight: 700;
}

//modal detail
.totalBox {
  // margin-right: 40px;
  .discount {
    display: flex;
    justify-content: space-between;
  }
  .price{
    display: flex;
    justify-content: flex-end;
  }
  .totalText {
    font-weight: 600;
  }
  .totalAmount {
    font-weight: 700;
    min-width: 80px;
    // text-align: center;
  }
}

.boxFooter {
  margin-top: 30px;
  .labelModal {
    margin-bottom: 1rem;
  }
  .info {
    margin-bottom: 1rem;
    font-weight: 700;
  }
}
